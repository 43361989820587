/**
 * 环境配置封装
 */
const env = process.env.VUE_APP_NODE_ENV || 'prod'

const EnvConfig = {
  dev: {
    // baseUrl: 'http://10.103.250.81:6060',
    baseUrl: 'https://piaoju.lenovo.com',
    baseApi: '/api',
    mockApi:
      'https://www.fastmock.site/mock/6fdfd5245e5b4334846cb564147f396e/api',
    platformId: '382a3470a55c4f7181f3ccbe674e62c2',
  },
  test: {
    baseUrl: 'http://10.103.250.81:6060',
    baseApi: '/receiptApi',
    mockApi:
      'https://www.fastmock.site/mock/6fdfd5245e5b4334846cb564147f396e/api',
    platformId: '382a3470a55c4f7181f3ccbe674e62c2',
  },
  prod: {
    baseUrl: 'https://piaoju.lenovo.com',
    baseApi: '/receiptApi',
    mockApi: '',
    platformId: '382a3470a55c4f7181f3ccbe674e62c2',
  },
}

export default {
  env,
  mock: false,
  namespace: 'lpst',
  ...EnvConfig[env],
}
