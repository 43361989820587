import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: storage.getItem('token') || '',
    menus: storage.getItem('menus') || [],
    username: storage.getItem('username') || '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setMenus(state, menus) {
      state.menus = menus
    },
    setUsername(state, username) {
      state.username = username
    },
    clearAllStore(state) {
      state.token = ''
      state.menus = []
      state.username = ''
      storage.clearAll()
    },
  },
  actions: {},
  modules: {},
})

store.subscribe(({ type, payload }) => {
  switch (type) {
    case 'setMenus':
      storage.setItem('menus', payload)
      break
    case 'setUsername':
      storage.setItem('username', payload)
      break
    case 'setToken':
      storage.setItem('token', payload)
      break
  }
})

export default store
