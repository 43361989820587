import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index/index'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/home'),
      },
      {
        path: '/bill-financing',
        component: () => import('@/views/bill-financing/bill-financing'),
      },
      {
        path: '/discount-detail/:orderNo',
        component: () => import('@/views/discount-detail/discount-detail'),
        props: true,
      },
      {
        path: 'sign',
        component: () => import('@/views/sign/sign'),
      },
      {
        path: 'commercial-discount',
        component: () => import('@/views/sign/commercial-discount'),
      },
      {
        path: 'commercial-maoshen',
        component: () => import('@/views/sign/commercial-maoshen'),
      },
      {
        path: 'commercial-sign',
        component: () => import('@/views/sign/commercial-sign'),
      },
      {
        path: 'login',
        component: () => import('@/views/login/login'),
      },
      {
        path: 'forget-password',
        component: () => import('@/views/forget-password/forget-password'),
      },
      {
        path: '/register',
        component: () => import('@/views/register/register'),
      },
      {
        path: '/auth-success',
        component: () => import('@/views/auth/success'),
      },
      {
        path: '/user',
        component: {
          render: h => h('router-view'),
        },
        children: [
          {
            path: '/user/transaction-management',
            component: () =>
              import(
                'views/user/transaction-management/transaction-management'
              ),
          },
          {
            path: '/user/invoice-request',
            component: () =>
              import('views/user/invoice-request/invoice-request'),
          },
          {
            path: '/user/account-center',
            component: () => import('views/user/account-center/account-center'),
          },
        ],
      },
      {
        path: '/register-success',
        component: () => import('@/views/register/register-success'),
      },
    ],
  },
  {
    path: '/service-agreement',
    component: () => import('@/views/register/service-agreement'),
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/register/privacy-policy'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})
router.afterEach(() => {
  const content = document.getElementById('content')
  if (content) {
    content.scrollTop = 0
  }
})

export default router
